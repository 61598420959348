import "./src/styles/global.css"
import mixpanel from 'mixpanel-browser';

mixpanel.init('881514fb287a2bb0dc2d0156abec02cb');

export const onRouteUpdate = ({ location }) => {
  let params = Object.fromEntries(new URLSearchParams(location.search))
  mixpanel.track('page-view', {
    path: location.pathname,
    query: location.query,
    ...params
  })
}